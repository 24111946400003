import Vue from 'vue'

const mutations = {
  toggleMenu: (state) => {
    state.menu.isOpen = !state.menu.isOpen
  },
  updateBrand: (state, brand) => {
    Vue.set(state, 'brand', brand)
  },
  updateFaqs: (state, faqs) => {
    Vue.set(state, 'faqs', faqs)
  }
}

export default mutations
