const state = {
  menu: {
    type: 'mobile',
    isOpen: false
  },
  pages: [
    {
      id: 1,
      name: 'Home',
      link: '/'
    }
  ],
  brand: {
    name: 'sait',
    logo: '../assets/img/logo.png',
    phone: '08084415480',
    email: 'io@sait.com.ng',
    address: ''
  },
  brandUrlBase: process.env.VUE_APP_ROOT_API,
  faqs: {}
}

export default state
