import Vue from 'vue'

const actions = {
  menuVisibility (store) {
    store.commit('toggleMenu')
  },
  getBrand (store) {
    Vue.axios.get(store.getters.apiBase + '/dev/brand').then(res => {
      if (res.data.status === 'success') {
        store.commit('updateBrand', res.data.brand)
      }
    })
  },
  getFaqs (store) {
    Vue.axios.get(store.getters.apiBase + '/dev/faqs').then(res => {
      if (res.data.status === 'success') {
        store.commit('updateFaqs', res.data.items)
      }
    })
  }
}

export default actions
