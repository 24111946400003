import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './assets/css/main.css'
import './assets/scss/custom.scss'

const app = createApp(App).use(router).use(store)
app.mount('#app')
