const getters = {
  menuState: state => {
    return state.menu.isOpen
  },
  appPages: state => {
    return state.pages
  },
  appBrand: state => {
    return state.brand
  },
  apiBase: state => {
    return (process.env.NODE_ENV === 'production') ? 'https://sait.com.ng/api' : 'http://dev.sait.com.ng/api'
  },
  appFaqs: state => {
    return state.faqs
  }
}

export default getters
